import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './availability.css';
import map from '../../assets/map.png';
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from 'react-router-dom';
import { getDatabase, ref, onValue } from "firebase/database";
import Popup from '../../Components/ADpopUp';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function MyCalender(props) {
  const { t } = useTranslation();
  const [date, setDate] = useState('');
  const [selectedOption, setSelectedOption] = useState('AC Hall');
  const [dropvalue, DropsetValue] = React.useState('');
  const [getmonth, setMonth] = React.useState('');
  const [serverMonth, setServerMonth] = React.useState('');
  const [getOptions, setOptions] = React.useState([]);
  const [disabledDates, setDisableDates] = React.useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  // const [submittedData, setSubmittedData] = useState(null);
  const [price, setPrice] = useState({
    Ac: '',
    Non_Ac: ''
  });
  const navigate = useNavigate();
  const db = getDatabase();
  const isAdmin = props.isAdmin
  let engagementDetails = {};

  const location = useLocation();
  const previousLocation = useRef(location); // Store the previous location

  useEffect(() => {
      // console.log("location----",location);
      const handleBackButtonClick = () => {
          // console.log('Back button clicked');
          // Custom logic here, e.g., navigate somewhere else
          navigate('/',{ state: null });
      };

      // if (previousLocation.current !== location) {
          if (location.pathname==="/") {
              handleBackButtonClick();
          // }
      }

      previousLocation.current = location; // Update previous location on every render
  }, []);


  useEffect(() => {
    // console.log(engagementDetails);
    localStorage.removeItem('booking');
    const starCountRef = ref(db, 'available_month');
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      setMonth(data.Value)
    });

    const query = ref(db, "Functions/" + t('LANG')+"/functionList");
    onValue(query, (snapshot) => {
      const data = snapshot.val();
      const getdata = [];
      if (snapshot.exists()) {
        // Push default option
        getdata.push({ label: '--Select--', value: 'Null' });

        // Iterate over each item in the snapshot and push them into getdata array
        Object.values(data).forEach((res) => {
          getdata.push({ label: res, value: res });
        });

        // Log the formatted data
        setOptions(getdata)

      }
    });

    const starCountRef1 = ref(db, 'bookedDates/');
    onValue(starCountRef1, (snapshot) => {
      const data1 = snapshot.val();
      if (snapshot.exists()) {
        const bDates = Object.values(data1).map(entry => {
          const parts = entry.formatedDate.split('/');
          const formattedDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
          return {
            date: new Date(formattedDateString),
            status: entry.status
          };
        });
        setDisableDates(bDates)

      }
    });

  }, [t, db])

  useEffect(() => {
    const serverDatetimeRef = ref(db, '.info/serverTimeOffset');

    // Listen for changes in the server time offset
    onValue(serverDatetimeRef, (snapshot) => {
      // Get the server time offset
      const currentDate = new Date();
      const offset = snapshot.val() || 0;
      const serverTime = new Date(currentDate.getTime() + offset);
      setServerMonth(serverTime.getMonth());
    });
  })

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleSubmit = (data) => {
    let userData = data;
    engagementDetails = {
      date: date,
      price: price,
      function: dropvalue,
      type: selectedOption,
      AdminData: userData,
    };
    navigate("/Terms", { state: { data: engagementDetails } })
  };

  // const options = [
  //   { label: '--Select--', value: 'Null' },
  //   { label: 'function1', value: 'function1' },

  //   { label: 'function2', value: 'function2' },

  //   { label: 'function3', value: 'function3' },
  // ];

  // const bDates = [
  //   new Date('2024-03-25'),
  //   new Date('2024-03-26'),
  //   new Date('2024-03-27'),
  //   new Date('2024-04-09')
  // ];

  const tileDisabled = ({ date, view }) => {
    const filteredDates = disabledDates.filter(item => item.status === 'blocked').map(item => item.date);
    // Check if the view is 'month' and date falls on any of the specified dates
    if (view === 'month') {
      return filteredDates.some((bDate) => date.getDate() === bDate?.getDate() && date.getMonth() === bDate?.getMonth() && date.getFullYear() === bDate?.getFullYear());
    }
    return false;
  };

  function handleChange(event) {
    setSelectedOption(event.target.value);
  }

  const handleDropChange = (event) => {
    DropsetValue(event.target.value);
  };
  // let engagementDetails = {};

  const bookNow = () => {
    if (date !== '') {
      if (dropvalue !== 'Null' && dropvalue !== '') {
        // if (!isAdmin) {
        engagementDetails = {
          date: date,
          price: price,
          function: dropvalue,
          type: selectedOption
        };
        //   navigate("/Terms", { state: { data: engagementDetails } })
        // } else {
        setIsPopupOpen(true);
        // }
      } else {
        alert(t('Select_function'));
      }
    } else {
      alert(t('Select_Date'));
    }
  }

  const currentDate = new Date();

  // Get the year of the current date
  let year = currentDate.getFullYear();

  // Get the month of the current date
  let admonth = currentDate.getMonth();
  let month = serverMonth;

  // Increment the month by 1 to get the next month
  month = month + getmonth;

  // If the current month is December (11), reset the month to January (0) and increment the year
  if (month === 12) {
    month = 0; // January
    year++;
  }

  const nextMonthDate = (!isAdmin) ? new Date(year, month, currentDate.getDate()) : new Date(year + 1, admonth, currentDate.getDate());

  const onDateSelected = (date) => {
    setDate("");
    let selectedDate = date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear()
    if (disabledDates.some(disabledDate => date.getDate() === disabledDate.date.getDate() && date.getMonth() === disabledDate.date.getMonth() && date.getFullYear() === disabledDate.date.getFullYear())) {
      setDate("");
      alert(`Selected date ${selectedDate} is Booked.`);
    } else {
      setDate(date);
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const query = ref(db, "Prices");
      onValue(query, (snapshot) => {
        const data = snapshot.val();
        let monthName = months[date.getMonth()]
        setPrice(data[monthName])
      });
    }
  }

  function DropDownBST() {
    return (
      <label style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {t('Function_type')}
        <select value={dropvalue} onChange={handleDropChange}>
          {getOptions.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
      </label>
    );
  }

  function RadioButtonGroup(props) {
    return (
      <div>
        <label style={{ marginBottom: 10 }}>
          <input
            type="radio"
            value="AC Hall"
            checked={props.selectedOption === 'AC Hall'}
            onChange={props.handleChange}
          />
          {t('Ac_hall')} - {t('Rs')} {price?.acTotal}
        </label>
        <br />
        <label>
          <input
            type="radio"
            value="Non AC Hall"
            checked={props.selectedOption === 'Non AC Hall'}
            onChange={props.handleChange}
          />
          {t('Non_ac_hall')} - {t('Rs')} {price?.NonAcTotal}
        </label>
        <br />
      </div>
    );
  }

  function AvailabilitySquares() {
    const squareStyle = {
      width: '18px',
      height: '18px',
      // margin: '8px',
      marginBottom:'15px',
      border: '0.5px solid black'
    };
  
    return (
      <div className="container mt-3">
      <h5 style={{ color: 'white', marginLeft: '5px' }}>Availability Status</h5>
      
      <div className="row justify-content-center">
        
        {/* Red Square: Unavailable */}
        <div className="col-6 col-md-3 d-flex align-items-center justify-content-center mb-2">
          <div style={{ ...squareStyle, backgroundColor: 'red' }}></div>
          <p className="d-flex align-items-center justify-content-center">Unavailable</p>
        </div>

        {/* Green Square: Booked */}
        <div className="col-6 col-md-3 d-flex align-items-center justify-content-center mb-2">
          <div style={{ ...squareStyle, backgroundColor: 'green' }}></div>
          <p className="d-flex align-items-center justify-content-center">Booked</p>
        </div>

        {/* Yellow Square: Temporarily Unavailable */}
        <div className="col-6 col-md-3 d-flex align-items-center justify-content-center mb-2">
          <div style={{ ...squareStyle, backgroundColor: 'yellow', width:25 }}></div>
          <p className="d-flex align-items-center justify-content-center">Temporarily Unavailable</p>
        </div>

        {/* White Square: Available */}
        <div className="col-6 col-md-3 d-flex align-items-center justify-content-center mb-2">
          <div style={{ ...squareStyle, backgroundColor: 'white' }}></div>
          <p className="d-flex align-items-center justify-content-center">Available</p>
        </div>
      </div>
    </div>
  );
  }

  // console.log("--->", tileDisabled, disabledDates);

  return (
    <div>
      <div className="headingContainer">
        <p className={isAdmin ? "Calender_heading-black" : "Calender_heading"} >{t('Dates_heading')}</p>
      </div>
      <div className="viewholder">
        <div className="calenderContainer">
      <AvailabilitySquares/>
          <Calendar onChange={onDateSelected} value={date} style={{ height: 500 }}
            minDate={new Date()} maxDate={nextMonthDate} locale={t('LANG')}
            tileDisabled={tileDisabled}
            tileClassName={({ date, view }) => {
              const filteredDates = disabledDates.filter(item => item.status === 'pending').map(item => item.date);
              const bookedDates = disabledDates.filter(item => item.status === 'approved').map(item => item.date);
              if (filteredDates.some((bDate) => date.getDate() === bDate?.getDate() && date.getMonth() === bDate?.getMonth() && date.getFullYear() === bDate?.getFullYear())) {
                return 'highlight'
              }
              if (bookedDates.some((bDate) => date.getDate() === bDate?.getDate() && date.getMonth() === bDate?.getMonth() && date.getFullYear() === bDate?.getFullYear())) {
                return 'highlight-gr'
              }
            }}
          />
          
        </div>
        
        <div className="calender_rightViewHolder">
          <a href="https://maps.app.goo.gl/7CDFGiXxGYYPSGMu7">
            <img src={map} alt="" className="map" />
          </a>
          <div className="roomType">
            <div className="roomTypeContainer">
              <div className="dropdown">
                <DropDownBST />
              </div>
              <div>
                <RadioButtonGroup
                  selectedOption={selectedOption}
                  handleChange={handleChange}
                />
              </div>
              {/* <a href='/Terms'> */}
              <button className="bookNow" onClick={bookNow}>{t('Book_now')}</button>
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Popup isOpen={isPopupOpen} onClose={handleClosePopup} onSubmit={handleSubmit} Details={
          engagementDetails = {
            date: date,
            price: price,
            function: dropvalue,
            type: selectedOption
          }
        } />
      </div>
      <p className="listitems" style={{ marginTop: 50 }}>
        {t('Contact_details')}
        <br />
      </p>
      <div className="contactDetails">
        <p>{t('Rudresh')} +91 9008806484 </p>
        <p className="manager">{t('Kantaraju')} +91 9740570582</p>
      </div>
      <div className="container_seprator" />
    </div>
  );
}
