import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue, update, set, remove, get, child } from "firebase/database";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function Updates() {
    const [inputValue, setInputValue] = useState('');
    const [lockedDates, setLockedDates] = useState([]);
    const [Prices, setPrices] = useState([]);
    const [Phone, setPhone] = useState([]);
    const [customItemsEn, setCustomItemsEn] = useState([]);
    const [customItemsKn, setCustomItemsKn] = useState([]);
    const db = getDatabase();

    const [selectedDate, setSelectedDate] = useState(new Date());

    useEffect(() => {
        const starCountRef = ref(db, 'available_month');
        onValue(starCountRef, (snapshot) => {
            const data = snapshot.val();
            setInputValue(data.Value)
        });
        const starCountRef1 = ref(db, 'Prices');
        onValue(starCountRef1, (snapshot) => {
            const data = snapshot.val();
            const sortedData = Object.entries(data)
                .map(([month, monthData]) => ({
                    month: month,
                    numericalMonth: getNumericalMonth(month),
                    data: monthData
                }))
                .sort((a, b) => a.numericalMonth - b.numericalMonth);
            setPrices(sortedData);
        });
        const starCountRef3 = ref(db, 'bookedDates');
        onValue(starCountRef3, (snapshot) => {
            const data = snapshot.val();
            setLockedDates(data)
        });

        const queryEn = ref(db, "Functions/EN-en/functionList");
        onValue(queryEn, (snapshot) => {
            const data = snapshot.val();
            const getdata = [];
            if (snapshot.exists()) {
                // Push default option
                getdata.push({ label: '--Select--', value: 'Null' });

                // console.log("obj", data.Object);
                // Iterate over each item in the snapshot and push them into getdata array
                Object.values(data).forEach((res) => {
                    getdata.push({ label: res, value: res });
                });

                // Log the formatted data
                setCustomItemsEn(getdata)

            }
        });

        const queryKn = ref(db, "Functions/KN-kn/functionList");
        onValue(queryKn, (snapshot) => {
            const data = snapshot.val();
            const getdata = [];
            if (snapshot.exists()) {
                // Push default option
                getdata.push({ label: '--Select--', value: 'Null' });

                // console.log("obj", data);
                // Iterate over each item in the snapshot and push them into getdata array
                Object.values(data).forEach((res) => {
                    getdata.push({ label: res, value: res });
                });

                // Log the formatted data
                setCustomItemsKn(getdata)

            }
        });
    }, [db])

    function ListboxWithDropdown() {
        // State for the selected item in the dropdown
        const [selectedItemEn, setSelectedItemEn] = useState('Null');
        const [selectedItemKn, setSelectedItemKn] = useState('Null');
        const [newItemEn, setNewItemEn] = useState('');
        const [newItemKn, setNewItemKn] = useState('');
        const date = new Date();

        // Handle adding a new item
        const handleAddItem = () => {
            if (newItemEn.trim() !== '') {
                const newOption = { label: newItemEn, value: newItemEn };
                setCustomItemsEn([...customItemsEn, newOption]);
                setNewItemEn(''); // Clear the input field
                const updatedItems = [...customItemsEn, newOption].filter(item =>  item.value !== 'Null');
                const functionList = { }; // Engagement is the first value

                updatedItems.forEach((item, index) => {
                    functionList[date.getTime()+index] = item.value;
                })
                set(ref(db, 'Functions/EN-en'), {
                    functionList
                });
            } else if (newItemKn.trim() !== '') {
                const newOption = { label: newItemKn, value: newItemKn };
                setCustomItemsKn([...customItemsKn, newOption]);
                setNewItemKn(''); // Clear the input field
                const updatedItems = [...customItemsKn, newOption].filter(item =>  item.value !== 'Null');
                const functionList = { }; // Engagement is the first value

                updatedItems.forEach((item, index) => {
                    functionList[date.getTime()+index] = item.value;
                })
                set(ref(db, 'Functions/KN-kn'), {
                    functionList
                });
            }
        };

        // Handle deleting an item
        const handleDeleteItem = () => {
            if (selectedItemEn != 'Null') {
                const updatedItems = customItemsEn.filter(item => item.value !== selectedItemEn && item.value !== 'Null');
                const functionList = { }; // Engagement is the first value

                updatedItems.forEach((item, index) => {
                    functionList[date.getTime()+index] = item.value;
                })
                set(ref(db, 'Functions/EN-en'), {
                    functionList
                });

            }
            if (selectedItemKn != 'Null') {
                const updatedItems = customItemsKn.filter(item => item.value !== selectedItemKn && item.value !== 'Null');
                // setCustomItemsKn(updatedItems);
                const functionList = { }; // Engagement is the first value

                updatedItems.forEach((item, index) => {
                    functionList[date.getTime()+index] = item.value;
                })
                set(ref(db, 'Functions/KN-kn'), {
                    functionList
                });
            }
        };

        return (
            <><h4 style={{ display: 'flex', alignItems: 'center', width: '50%', marginLeft: 50, justifyContent: 'flex-start' }}>Add Functions</h4>
                <div style={{ display: 'flex', alignItems: 'center', width: '50%', marginLeft: 50, justifyContent: 'flex-start' }}>

                    <label>English</label>
                    <input
                        type="text"
                        value={newItemEn}
                        onChange={(e) => setNewItemEn(e.target.value)}
                        placeholder="Enter new event"
                        style={{ marginLeft: '10px', width: '200px', marginRight: '20px' }} />

                    <label>Kannada</label>
                    <input
                        type="text"
                        value={newItemKn}
                        onChange={(e) => setNewItemKn(e.target.value)}
                        placeholder="Enter new event"
                        style={{ marginLeft: '10px', width: '200px', marginRight: '20px' }} />
                    <button onClick={handleAddItem} style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer' }}>
                        Add
                    </button>
                </div>

                <h4 style={{ display: 'flex', alignItems: 'center', width: '50%', marginLeft: 50, justifyContent: 'flex-start', marginTop: 20 }}>Delete Functions</h4>
                <div style={{ display: 'flex', alignItems: 'center', width: '50%', marginLeft: 50, justifyContent: 'flex-start' }}>
                    <label>English</label>
                    <select
                        value={selectedItemEn}
                        onChange={(e) => setSelectedItemEn(e.target.value)}
                        style={{ marginLeft: '10px', width: '200px', marginRight: '20px' }}
                    >
                        {customItemsEn.map((item, index) => (
                            <option key={index} value={item.value}>
                                {item.label}
                            </option>
                        ))}
                    </select>

                    <label>Kannada</label>
                    <select
                        value={selectedItemKn}
                        onChange={(e) => setSelectedItemKn(e.target.value)}
                        style={{ marginLeft: '10px', width: '200px', marginRight: '20px' }}
                    >
                        {customItemsKn.map((item, index) => (
                            <option key={index} value={item.value}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                    <button onClick={() => handleDeleteItem()} style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer' }}>
                        Delete
                    </button>
                </div></>
        );
    }

    const getNumericalMonth = (month) => {
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return monthNames.indexOf(month) + 1;
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleUpdate = (month) => {
        // Perform update logic here
        const selectedMonthData = Prices.find(entry => entry.month === month);
        const acValue = selectedMonthData.data.Ac.replace('/-', '');
        const acGST = selectedMonthData.data.Ac_Gst.replace('/-', '');
        const nonAcValue = selectedMonthData.data.Non_Ac.replace('/-', '');
        const nonAcGST = selectedMonthData.data.Non_Ac_Gst.replace('/-', '');
        const minPrice = selectedMonthData.data.Min_price.replace('/-', '');
        const acTotal = parseInt(acValue) + parseInt(acGST);
        const NonAcTotal = parseInt(nonAcValue) + parseInt(nonAcGST)
        alert(`Updating values for ${month} Ac: ${acTotal}/-  Non_AC: ${NonAcTotal}/-`);

        const updates = {

        };
        updates['/Prices/' + month] = {
            Ac: acValue + '/-',
            Ac_Gst: acGST + '/-',
            Non_Ac: nonAcValue + '/-',
            Non_Ac_Gst: nonAcGST + '/-',
            Min_price: minPrice + '/-',
            acTotal: acTotal + '/-',
            NonAcTotal: NonAcTotal + '/-'

        };
        update(ref(db), updates);

    };

    const handleDateUpdate = () => {
        const formatedDate = formatDate(selectedDate);
        let timeNow = new Date().getTime();
        const foundItem = Object.entries(lockedDates).find(([key, value]) => {
            return value.formatedDate === formatedDate;
        });
        if (foundItem) {
            alert(`${formatedDate} already blocked`);
        } else {
            alert(`Blocked date: ${formatedDate}`);
            set(ref(db, 'bookedDates/' + timeNow), {
                formatedDate,
                blocked: true,
                status: "blocked"
            });
        }
    };

    const handleDateUnBlockUpdate = () => {
        const formatedDate = formatDate(selectedDate);
        const foundItem = Object.entries(lockedDates).find(([key, value]) => {
            return value.formatedDate === formatedDate;
        });

        if (foundItem) {
            const [parentId, data] = foundItem;
            if (data.blocked === true) {
                remove(ref(db, "/bookedDates/" + parentId));
                alert(`Unblocked Date: ${formatedDate}`)
            } else {
                alert(`${formatedDate} this date is Booked by customer`)
            }
        } else {
            alert(`${formatedDate} this date not blocked`)
        }
    };

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-IN', options);
    };

    const handleClick = () => {
        if (inputValue > 0 && inputValue < 13) {
            const updates = {};
            updates['/available_month/Value'] = Number(inputValue);
            update(ref(db), updates);
            alert('Display Months updated');
        } else {
            alert('Months range should be between 0 t0 13');
        }
    };

    const handlePhoneUpdate = () => {
        const starCountRef = ref(db);
        get(child(starCountRef, 'UsersList/' + Phone)).then((snapshot) => {
            const data1 = snapshot.val();
            if (snapshot.exists()) {
                const updates = {};
                updates['UsersList/' + Phone] = {
                    ...data1, blocked: true
                };
                update(ref(db), updates);
                alert(`${Phone} User blocked`)
            } else {
                alert("User not found")
            }
        });

    }

    const handlePhoneUnBlockUpdate = () => {
        const starCountRef = ref(db);
        get(child(starCountRef, 'UsersList/' + Phone)).then((snapshot) => {
            const data1 = snapshot.val();
            if (snapshot.exists()) {
                const updates = {};
                updates['UsersList/' + Phone] = {
                    ...data1, blocked: false
                };
                update(ref(db), updates);
                alert(`${Phone} User Unblocked`)
            } else {
                alert("User not found")
            }
        });

    }

    return (
        <>
            <h1 className='Calender_heading-black'>UPDATES</h1>
            <div style={{ display: 'flex', alignItems: 'center', width: '50%', marginLeft: 50, justifyContent: 'flex-start' }}>
                <h4 style={{ marginRight: '20px' }}>Display Months</h4>
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    style={{ marginLeft: '10px', width: '100px', marginRight: '20px' }}
                />
                <button onClick={handleClick} style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer' }}>Update</button>

            </div>

            <div style={{ width: '100%', marginTop: 20 }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginLeft: 50, justifyContent: 'flex-start' }}>
                    <h4 style={{ marginRight: '20px' }}>Block Date</h4>
                    <DatePicker
                        selected={selectedDate}
                        onChange={date => setSelectedDate(date)}
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        style={{ marginBottom: '20px' }}
                    />
                    <button onClick={handleDateUpdate} style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer', marginLeft: 30 }}>Block</button>
                    <button onClick={handleDateUnBlockUpdate} style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer', marginLeft: 30 }}>UnBlock</button>
                </div>
            </div>
            <div style={{ width: '100%', marginTop: 20 }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginLeft: 50, justifyContent: 'flex-start' }}>
                    <h4 style={{ marginRight: '20px' }}>Block User</h4>
                    <input
                        type="text"
                        placeholder='Phone Number'
                        value={Phone}
                        onChange={(e) => {
                            setPhone(e.target.value);
                        }}
                        style={{ marginLeft: '10px', width: '200px' }} />
                    <button onClick={handlePhoneUpdate} style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer', marginLeft: 30 }}>Block</button>
                    <button onClick={handlePhoneUnBlockUpdate} style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer', marginLeft: 30 }}>UnBlock</button>
                </div>
            </div>
            <div style={{ width: '60%', height: 1, backgroundColor: 'black', marginTop: 10, marginBottom: 20, marginLeft: 50 }} />
            <ListboxWithDropdown />
            <div style={{ width: '60%', height: 1, backgroundColor: 'black', marginTop: 10, marginLeft: 50 }} />
            <h3 style={{ marginTop: 50, marginLeft: 50 }}>Update Monthly Prices</h3>
            <div style={{ width: '100%', marginLeft: 50, marginTop: 20, marginRight: 50 }}>
                <div style={{ width: '60%', height: 1, backgroundColor: 'black', marginTop: 10, marginBottom: 20 }} />
                {Prices.map((entry, index) => (
                    <><div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                        <h4 style={{ alignSelf: 'center', width: 100 }}>{entry.month}</h4>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 120 }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <div style={{ marginRight: '20px' }}>
                                    <label style={{ marginRight: '40px' }}>Ac:</label>
                                    <input
                                        type="text"
                                        value={entry.data.Ac !== "" ? parseInt(entry.data.Ac.replace('/-', '')) : 0}
                                        onChange={(e) => {
                                            const newData = [...Prices];
                                            newData[index].data.Ac = e.target.value === "" ? 0 + '/-' : e.target.value;
                                            setPrices(newData);
                                        }}
                                        style={{ marginLeft: '10px', width: '80px' }} />
                                </div>
                                <div style={{ marginRight: '20px' }}>
                                    <label> + GST</label>
                                    <input
                                        type="text"
                                        placeholder='GST'
                                        value={entry.data.Ac_Gst !== "" ? parseInt(entry.data.Ac_Gst.replace('/-', '')) : 0}
                                        onChange={(e) => {
                                            const newData = [...Prices];
                                            newData[index].data.Ac_Gst = e.target.value === "" ? 0 + '/-' : e.target.value;
                                            setPrices(newData);
                                        }}
                                        style={{ marginLeft: '10px', width: '80px' }} />
                                </div>
                                <div style={{ marginRight: '20px' }}>
                                    <label>Total</label>
                                    <input
                                        type="text"
                                        value={(parseInt(entry.data.Ac.replace('/-', '')) + parseInt(entry.data.Ac_Gst.replace('/-', ''))) + '/-'}
                                        readOnly
                                        style={{ marginLeft: '10px', width: '100px' }} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <div style={{ marginRight: '20px' }}>
                                    <label style={{ marginRight: '7px' }}>Non Ac:</label>
                                    <input
                                        type="text"
                                        value={entry.data.Non_Ac !== "" ? parseInt(entry.data.Non_Ac.replace('/-', '')) : 0}
                                        onChange={(e) => {
                                            const newData = [...Prices];
                                            newData[index].data.Non_Ac = e.target.value === "" ? 0 + '/-' : e.target.value;
                                            setPrices(newData);
                                        }}
                                        style={{ marginLeft: '10px', width: '80px' }} />
                                </div>
                                <div style={{ marginRight: '20px' }}>
                                    <label>+ GST</label>
                                    <input
                                        type="text"
                                        placeholder='GST'
                                        value={entry.data.Non_Ac_Gst !== "" ? parseInt(entry.data.Non_Ac_Gst.replace('/-', '')) : 0}
                                        onChange={(e) => {
                                            const newData = [...Prices];
                                            newData[index].data.Non_Ac_Gst = e.target.value === "" ? 0 + '/-' : e.target.value;
                                            setPrices(newData);
                                        }}
                                        style={{ marginLeft: '10px', width: '80px' }} />
                                </div>
                                <div style={{ marginRight: '20px' }}>
                                    <label>Total</label>
                                    <input
                                        type="text"
                                        value={(parseInt(entry.data.Non_Ac.replace('/-', '')) + parseInt(entry.data.Non_Ac_Gst.replace('/-', ''))) + '/-'}
                                        readOnly
                                        style={{ marginLeft: '10px', width: '100px' }} />
                                </div>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <div style={{ marginRight: '20px' }}>
                                    <label>Minmum Amount:</label>
                                    <input
                                        type="text"
                                        value={entry.data.Min_price !== "" ? parseInt(entry.data.Min_price.replace('/-', '')) : 0}
                                        onChange={(e) => {
                                            const newData = [...Prices];
                                            newData[index].data.Min_price = e.target.value === "" ? 0 + '/-' : e.target.value;
                                            setPrices(newData);
                                        }}
                                        style={{ marginLeft: '10px', width: '100px' }} />
                                </div>
                            </div>
                        </div>
                        <button onClick={() => handleUpdate(entry.month)} style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer', height: 50, alignSelf: 'center' }}>Update</button>
                    </div><div style={{ width: '60%', height: 1, backgroundColor: 'black', marginTop: 10, marginBottom: 20 }} /></>
                ))}
            </div>
        </>
    );
}

export default Updates;
