import React from 'react';
import './services.css';
import { useTranslation } from "react-i18next";
function Services() {
  const { t } = useTranslation();
  return (
    <div className='bg-service'>
      <div className="MainContainer">
        <div className="leftViewHolder">
          <div>
            <p className="service_heading">{t('Included_serivce')}</p>
          </div>
          <div className="listitems">
            <li>{t('Reception_seats')}</li>
            <li>{t('Dining_seats')}</li>
            <li>{t('Bathroom')}</li>
            <li>{t('kitchen')}</li>
            <li>{t('Grinder')}</li>
            <li>{t('Cleaning_service')}</li>
            <li>{t('Stove')}</li>
            <li>{t('Electricity')}</li>
            <li>{t('Generator')}</li>
            <li>2 lifts</li>
          </div>
        </div>
        <div className="seperator" />
        <div className="rightViewHolder">
          <div>
            <p className="service_heading">{t('Other_services')}</p>
          </div>
          <p className="heading">
            {t('Undertake_contract')}
          </p>
          <div className="listitems">
            <li>{t('Cooking_contact')}</li>
            <li>{t('Videography')}</li>
            <li>{t('Decoration')}</li>
            <li>{t('Shamiyanaa')}</li>
            <li>{t('Commercial_gas')}</li>
            <li>{t('Mineral_water')}</li>
            <li>{t('Ice_cream')}</li>
            <li>{t('Beeda')}</li>
            <li>{t('Others')}</li>
          </div>
          <p className="listitems" style={{ marginTop: 50 }}>
            {t('More_details')}
            <br />
            <p className="contactDetails">{t('Rudresh')} +91 9008806484 </p>
            <p className="contactDetails">{t('Kantaraju')} +91 9740570582 </p>
            <br />
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}

export default Services;
